import React from "react";

const IntegrationsDestinationsCallout = ({ destination }) => {
    return (
        <section className="st-section tools-section">
            <div className="content">
                <h2 className="st-heading--2">Simplify your {destination.name} migration</h2>
                <p>
                    When it comes to replicating your data to {destination.name}, conventional ETL is no longer the only
                    game in town.
                </p>
                <p>
                    Writing ETL code requires big investments of time, money, and expertise that might otherwise be used
                    for innovation. Most importantly, newer approaches to data ingestion deliver faster implementation
                    than traditional ETL, so you can produce data analytics and business intelligence more quickly.
                </p>
                <p>This is where Stitch can help.</p>
            </div>
        </section>
    );
};

export default IntegrationsDestinationsCallout;
